*{
  box-sizing: border-box  ;
}
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 12.18px;
}